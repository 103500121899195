import React from 'react';
import './App.css';
import RVGrid from './components/RVGrid.tsx';
import Header from './components/Header.tsx';
import Footer from './components/Footer.tsx';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <main>
        <RVGrid />
      </main>
      <Footer />
    </div>
  );
}

export default App;