import React, { useState } from 'react';
import './RVFilter.css';

interface RVFilterProps {
  minPrice: number | null;
  maxPrice: number | null;
  minLength: number | null;
  maxLength: number | null;
  minHeight: number | null;
  maxHeight: number | null;
  minWidth: number | null;
  maxWidth: number | null;
  minGVW: number | null;
  maxGVW: number | null;
  minPayload: number | null;
  maxPayload: number | null;
  selectedParents: string[];
  selectedBrands: string[];
  selectedSeries: string[];
  selectedVehicleTypes: string[];
  setMinPrice: (value: number | null) => void;
  setMaxPrice: (value: number | null) => void;
  setMinLength: (value: number | null) => void;
  setMaxLength: (value: number | null) => void;
  setMinHeight: (value: number | null) => void;
  setMaxHeight: (value: number | null) => void;
  setMinWidth: (value: number | null) => void;
  setMaxWidth: (value: number | null) => void;
  setMinGVW: (value: number | null) => void;
  setMaxGVW: (value: number | null) => void;
  setMinPayload: (value: number | null) => void;
  setMaxPayload: (value: number | null) => void;
  setSelectedParents: (value: string[]) => void;
  setSelectedBrands: (value: string[]) => void;
  setSelectedSeries: (value: string[]) => void;
  setSelectedVehicleTypes: (value: string[]) => void;
  availableParents: string[];
  availableBrands: string[];
  availableSeries: string[];
  availableVehicleTypes: string[];
}

const RVFilter: React.FC<RVFilterProps> = ({
  minPrice, maxPrice, minLength, maxLength, minHeight, maxHeight, minWidth, maxWidth, minGVW, maxGVW, minPayload, maxPayload,
  selectedParents, selectedBrands, selectedSeries,
  setMinPrice, setMaxPrice, setMinLength, setMaxLength, setMinHeight, setMaxHeight, setMinWidth, setMaxWidth, setMinGVW, setMaxGVW, setMinPayload, setMaxPayload,
  setSelectedParents, setSelectedBrands, setSelectedSeries,
  availableParents, availableBrands, availableSeries
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);

  const handleCheckboxChange = (setter: (value: string[]) => void, value: string) => {
    setter((prev) => prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]);
  };

  const handleAddFilter = (filter: string) => {
    setSelectedFilter(filter);
  };

  return (
    <div className="filters">
      <button onClick={() => setShowDropdown(!showDropdown)}>Click to Edit Filters</button>
      {showDropdown && (
        <div className="dropdown">
          <button onClick={() => handleAddFilter('Price')}>Price</button>
          <button onClick={() => handleAddFilter('Length')}>Length</button>
          <button onClick={() => handleAddFilter('Height')}>Height</button>
          <button onClick={() => handleAddFilter('Width')}>Width</button>
          <button onClick={() => handleAddFilter('GVW')}>GVW</button>
          <button onClick={() => handleAddFilter('Payload')}>Payload</button>
          <button onClick={() => handleAddFilter('Parent Group')}>Parent Group</button>
          <button onClick={() => handleAddFilter('Brand')}>Brand</button>
          <button onClick={() => handleAddFilter('Model Series')}>Model Series</button>
        </div>
      )}

      {selectedFilter === 'Price' && showDropdown && (
        <div className="filter-inputs">
          <input type="number" placeholder="Min Price" onChange={(e) => setMinPrice(Number(e.target.value) || null)} />
          <input type="number" placeholder="Max Price" onChange={(e) => setMaxPrice(Number(e.target.value) || null)} />
        </div>
      )}

      {selectedFilter === 'Length' && showDropdown && (
        <div className="filter-inputs">
          <input type="number" placeholder="Min Length (m)" onChange={(e) => setMinLength(Number(e.target.value) || null)} />
          <input type="number" placeholder="Max Length (m)" onChange={(e) => setMaxLength(Number(e.target.value) || null)} />
        </div>
      )}

      {selectedFilter === 'Height' && showDropdown && (
        <div className="filter-inputs">
          <input type="number" placeholder="Min Height (m)" onChange={(e) => setMinHeight(Number(e.target.value) || null)} />
          <input type="number" placeholder="Max Height (m)" onChange={(e) => setMaxHeight(Number(e.target.value) || null)} />
        </div>
      )}

      {selectedFilter === 'Width' && showDropdown && (
        <div className="filter-inputs">
          <input type="number" placeholder="Min Width (m)" onChange={(e) => setMinWidth(Number(e.target.value) || null)} />
          <input type="number" placeholder="Max Width (m)" onChange={(e) => setMaxWidth(Number(e.target.value) || null)} />
        </div>
      )}

      {selectedFilter === 'GVW' && showDropdown && (
        <div className="filter-inputs">
          <input type="number" placeholder="Min GVW (kg)" onChange={(e) => setMinGVW(Number(e.target.value) || null)} />
          <input type="number" placeholder="Max GVW (kg)" onChange={(e) => setMaxGVW(Number(e.target.value) || null)} />
        </div>
      )}

      {selectedFilter === 'Payload' && showDropdown && (
        <div className="filter-inputs">
          <input type="number" placeholder="Min Payload (kg)" onChange={(e) => setMinPayload(Number(e.target.value) || null)} />
          <input type="number" placeholder="Max Payload (kg)" onChange={(e) => setMaxPayload(Number(e.target.value) || null)} />
        </div>
      )}

      {selectedFilter === 'Parent Group' && showDropdown && (
        <div className="checkbox-group">
          <h4>Parent</h4>
          <div className="checkbox-options">
            {availableParents.map((parent) => (
              <label key={parent}>
                <input type="checkbox" onChange={() => handleCheckboxChange(setSelectedParents, parent)} checked={selectedParents.includes(parent)} />
                {parent}
              </label>
            ))}
          </div>
        </div>
      )}

      {selectedFilter === 'Brand' && showDropdown && (
        <div className="checkbox-group">
          <h4>Brand</h4>
          <div className="checkbox-options">
            {availableBrands.map((brand) => (
              <label key={brand}>
                <input type="checkbox" onChange={() => handleCheckboxChange(setSelectedBrands, brand)} checked={selectedBrands.includes(brand)} />
                {brand}
              </label>
            ))}
          </div>
        </div>
      )}

      {selectedFilter === 'Model Series' && showDropdown && (
        <div className="checkbox-group">
          <h4>Series</h4>
          <div className="checkbox-options">
            {availableSeries.map((series) => (
              <label key={series}>
                <input type="checkbox" onChange={() => handleCheckboxChange(setSelectedSeries, series)} checked={selectedSeries.includes(series)} />
                {series}
              </label>
            ))}
          </div>
        </div>
      )}

      <div className="applied-filters">
        {minPrice !== null && <div>Min Price: {minPrice}</div>}
        {maxPrice !== null && <div>Max Price: {maxPrice}</div>}
        {minLength !== null && <div>Min Length: {minLength}m</div>}
        {maxLength !== null && <div>Max Length: {maxLength}m</div>}
        {minHeight !== null && <div>Min Height: {minHeight}m</div>}
        {maxHeight !== null && <div>Max Height: {maxHeight}m</div>}
        {minWidth !== null && <div>Min Width: {minWidth}m</div>}
        {maxWidth !== null && <div>Max Width: {maxWidth}m</div>}
        {minGVW !== null && <div>Min GVW: {minGVW}kg</div>}
        {maxGVW !== null && <div>Max GVW: {maxGVW}kg</div>}
        {minPayload !== null && <div>Min Payload: {minPayload}kg</div>}
        {maxPayload !== null && <div>Max Payload: {maxPayload}kg</div>}
        {selectedParents.length > 0 && <div>Parents: {selectedParents.join(', ')}</div>}
        {selectedBrands.length > 0 && <div>Brands: {selectedBrands.join(', ')}</div>}
        {selectedSeries.length > 0 && <div>Series: {selectedSeries.join(', ')}</div>}
      </div>
    </div>
  );
};

export default RVFilter;