import React, { useState } from 'react';
import './RVCard.css';
import { RV } from './types';
import RVCarousel from './RVCarousel.tsx';
import Modal from './Modal.tsx';
import QuoteForm from './QuoteForm.tsx';

interface RVCardProps {
  rv: RV;
}

const RVCard: React.FC<RVCardProps> = ({ rv }) => {
  const [showModal, setShowModal] = useState(false);

  // Extract the price value from the array
  const priceObject = rv.price?.[0];
  const priceValue = priceObject ? parseFloat(priceObject.price_value) : NaN;
  const formattedPrice = !isNaN(priceValue)
      ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: priceObject.price_currency || 'EUR', // Default to EUR if currency is not provided
          minimumFractionDigits: 0, // No decimal places
          maximumFractionDigits: 0, // No decimal places
        }).format(priceValue)
      : 'N/A';
  
    return (
      <div className="rv-card">
        <h3>{rv.brand}: {rv.series} {rv.name}</h3>
        <h6>(Parent Group: {rv.parent})</h6>
        <RVCarousel images={rv.images ?? []} />
        <h4>From {formattedPrice}</h4>
        <hr />
        <p>Berths: {rv.passengers?.[0]?.berths ?? 'N/A'}; Belts: {rv.passengers?.[0]?.seatbelts ?? 'N/A'}; Dining: {rv.passengers?.[0]?.dining_seats ?? 'N/A'}</p>
        <hr />
        <p>L: {rv.dimensions?.[0]?.length_m ?? 'N/A'}m; H: {rv.dimensions?.[0]?.height_m ?? 'N/A'}m; W: {rv.dimensions?.[0]?.width_m ?? 'N/A'}m</p>
        <hr />
        <p>{rv.chassis?.[0]?.chassis_manufacturer ?? 'N/A'} {rv.chassis?.[0]?.chassis_variant ?? 'N/A'} {rv.chassis?.[0]?.engine_liters ?? 'N/A'}L {rv.chassis?.[0]?.engine_fuel ?? 'N/A'} {rv.chassis?.[0]?.engine_hp ?? 'N/A'}HP</p>
        <hr />
        <p>GVW: {rv.weights?.[0]?.gvw_kg ?? 'N/A'}kg; Payload: {rv.weights?.[0]?.payload_kg ?? 'N/A'}kg</p>
        <button onClick={() => setShowModal(true)}>Compare Dealer Quotes</button>
        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <QuoteForm rv={`${rv.brand} ${rv.series} ${rv.name}`} onClose={() => setShowModal(false)} />
        </Modal>
      </div>
    );
  }
  
  export default RVCard;