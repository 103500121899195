import React, { useEffect, useState } from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    const user = 'alex';
    const domain = 'rvbig.com';
    setEmail(`${user}@${domain}`);
  }, []);

  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <footer className="App-footer">
      <p>&copy; {new Date().getFullYear()} rvbig. All rights reserved.</p>
      <button onClick={handleEmailClick}>Contact Us</button>
    </footer>
  );
}

export default Footer;