export const countryCodes = [
  { value: '93', label: 'Afghanistan (+93)', flag: '🇦🇫' },
  { value: '355', label: 'Albania (+355)', flag: '🇦🇱' },
  { value: '213', label: 'Algeria (+213)', flag: '🇩🇿' },
  { value: '1-684', label: 'American Samoa (+1-684)', flag: '🇦🇸' },
  { value: '376', label: 'Andorra (+376)', flag: '🇦🇩' },
  { value: '244', label: 'Angola (+244)', flag: '🇦🇴' },
  { value: '1-264', label: 'Anguilla (+1-264)', flag: '🇦🇮' },
  { value: '672-1', label: 'Antarctica (Australian bases) (+672-1)', flag: '🇦🇶' },
  { value: '1-268', label: 'Antigua and Barbuda (+1-268)', flag: '🇦🇬' },
  { value: '54', label: 'Argentina (+54)', flag: '🇦🇷' },
  { value: '374', label: 'Armenia (+374)', flag: '🇦🇲' },
  { value: '297', label: 'Aruba (+297)', flag: '🇦🇼' },
  { value: '247', label: 'Ascension (+247)', flag: '🇦🇨' },
  { value: '61', label: 'Australia (+61)', flag: '🇦🇺' },
  { value: '43', label: 'Austria (+43)', flag: '🇦🇹' },
  { value: '994', label: 'Azerbaijan (+994)', flag: '🇦🇿' },
  { value: '1-242', label: 'Bahamas (+1-242)', flag: '🇧🇸' },
  { value: '973', label: 'Bahrain (+973)', flag: '🇧🇭' },
  { value: '880', label: 'Bangladesh (+880)', flag: '🇧🇩' },
  { value: '1-246', label: 'Barbados (+1-246)', flag: '🇧🇧' },
  { value: '375', label: 'Belarus (+375)', flag: '🇧🇾' },
  { value: '32', label: 'Belgium (+32)', flag: '🇧🇪' },
  { value: '501', label: 'Belize (+501)', flag: '🇧🇿' },
  { value: '229', label: 'Benin (+229)', flag: '🇧🇯' },
  { value: '1-441', label: 'Bermuda (+1-441)', flag: '🇧🇲' },
  { value: '975', label: 'Bhutan (+975)', flag: '🇧🇹' },
  { value: '591', label: 'Bolivia (+591)', flag: '🇧🇴' },
  { value: '599', label: 'Bonaire (+599)', flag: '🇧🇶' },
  { value: '387', label: 'Bosnia and Herzegovina (+387)', flag: '🇧🇦' },
  { value: '267', label: 'Botswana (+267)', flag: '🇧🇼' },
  { value: '55', label: 'Brazil (+55)', flag: '🇧🇷' },
  { value: '1-284', label: 'British Virgin Islands (+1-284)', flag: '🇻🇬' },
  { value: '673', label: 'Brunei (+673)', flag: '🇧🇳' },
  { value: '359', label: 'Bulgaria (+359)', flag: '🇧🇬' },
  { value: '226', label: 'Burkina Faso (+226)', flag: '🇧🇫' },
  { value: '257', label: 'Burundi (+257)', flag: '🇧🇮' },
  { value: '238', label: 'Cabo Verde (+238)', flag: '🇨🇻' },
  { value: '855', label: 'Cambodia (+855)', flag: '🇰🇭' },
  { value: '237', label: 'Cameroon (+237)', flag: '🇨🇲' },
  { value: '1', label: 'Canada (+1)', flag: '🇨🇦' },
  { value: '1-345', label: 'Cayman Islands (+1-345)', flag: '🇰🇾' },
  { value: '236', label: 'Central African Republic (+236)', flag: '🇨🇫' },
  { value: '235', label: 'Chad (+235)', flag: '🇹🇩' },
  { value: '56', label: 'Chile (+56)', flag: '🇨🇱' },
  { value: '86', label: 'China (+86)', flag: '🇨🇳' },
  { value: '57', label: 'Colombia (+57)', flag: '🇨🇴' },
  { value: '269', label: 'Comoros (+269)', flag: '🇰🇲' },
  { value: '243', label: 'Congo, Democratic Republic of the (+243)', flag: '🇨🇩' },
  { value: '242', label: 'Congo, Republic of the (+242)', flag: '🇨🇬' },
  { value: '682', label: 'Cook Islands (+682)', flag: '🇨🇰' },
  { value: '506', label: 'Costa Rica (+506)', flag: '🇨🇷' },
  { value: '225', label: 'Cote d\'Ivoire (+225)', flag: '🇨🇮' },
  { value: '385', label: 'Croatia (+385)', flag: '🇭🇷' },
  { value: '53', label: 'Cuba (+53)', flag: '🇨🇺' },
  { value: '599', label: 'Curaçao (+599)', flag: '🇨🇼' },
  { value: '357', label: 'Cyprus (+357)', flag: '🇨🇾' },
  { value: '420', label: 'Czech Republic (+420)', flag: '🇨🇿' },
  { value: '45', label: 'Denmark (+45)', flag: '🇩🇰' },
  { value: '246', label: 'Diego Garcia (+246)', flag: '🇩🇬' },
  { value: '253', label: 'Djibouti (+253)', flag: '🇩🇯' },
  { value: '1-767', label: 'Dominica (+1-767)', flag: '🇩🇲' },
  { value: '1-809', label: 'Dominican Republic (+1-809)', flag: '🇩🇴' },
  { value: '1-829', label: 'Dominican Republic (+1-829)', flag: '🇩🇴' },
  { value: '1-849', label: 'Dominican Republic (+1-849)', flag: '🇩🇴' },
  { value: '593', label: 'Ecuador (+593)', flag: '🇪🇨' },
  { value: '20', label: 'Egypt (+20)', flag: '🇪🇬' },
  { value: '503', label: 'El Salvador (+503)', flag: '🇸🇻' },
  { value: '240', label: 'Equatorial Guinea (+240)', flag: '🇬🇶' },
  { value: '291', label: 'Eritrea (+291)', flag: '🇪🇷' },
  { value: '372', label: 'Estonia (+372)', flag: '🇪🇪' },
  { value: '268', label: 'Eswatini (+268)', flag: '🇸🇿' },
  { value: '251', label: 'Ethiopia (+251)', flag: '🇪🇹' },
  { value: '500', label: 'Falkland Islands (+500)', flag: '🇫🇰' },
  { value: '298', label: 'Faroe Islands (+298)', flag: '🇫🇴' },
  { value: '679', label: 'Fiji (+679)', flag: '🇫🇯' },
  { value: '358', label: 'Finland (+358)', flag: '🇫🇮' },
  { value: '33', label: 'France (+33)', flag: '🇫🇷' },
  { value: '594', label: 'French Guiana (+594)', flag: '🇬🇫' },
  { value: '689', label: 'French Polynesia (+689)', flag: '🇵🇫' },
  { value: '241', label: 'Gabon (+241)', flag: '🇬🇦' },
  { value: '220', label: 'Gambia (+220)', flag: '🇬🇲' },
  { value: '995', label: 'Georgia (+995)', flag: '🇬🇪' },
  { value: '49', label: 'Germany (+49)', flag: '🇩🇪' },
  { value: '233', label: 'Ghana (+233)', flag: '🇬🇭' },
  { value: '350', label: 'Gibraltar (+350)', flag: '🇬🇮' },
  { value: '30', label: 'Greece (+30)', flag: '🇬🇷' },
  { value: '299', label: 'Greenland (+299)', flag: '🇬🇱' },
  { value: '1-473', label: 'Grenada (+1-473)', flag: '🇬🇩' },
  { value: '590', label: 'Guadeloupe (+590)', flag: '🇬🇵' },
  { value: '1-671', label: 'Guam (+1-671)', flag: '🇬🇺' },
  { value: '502', label: 'Guatemala (+502)', flag: '🇬🇹' },
  { value: '224', label: 'Guinea (+224)', flag: '🇬🇳' },
  { value: '245', label: 'Guinea-Bissau (+245)', flag: '🇬🇼' },
  { value: '592', label: 'Guyana (+592)', flag: '🇬🇾' },
  { value: '509', label: 'Haiti (+509)', flag: '🇭🇹' },
  { value: '504', label: 'Honduras (+504)', flag: '🇭🇳' },
  { value: '852', label: 'Hong Kong (+852)', flag: '🇭🇰' },
  { value: '36', label: 'Hungary (+36)', flag: '🇭🇺' },
  { value: '354', label: 'Iceland (+354)', flag: '🇮🇸' },
  { value: '91', label: 'India (+91)', flag: '🇮🇳' },
  { value: '62', label: 'Indonesia (+62)', flag: '🇮🇩' },
  { value: '98', label: 'Iran (+98)', flag: '🇮🇷' },
  { value: '964', label: 'Iraq (+964)', flag: '🇮🇶' },
  { value: '353', label: 'Ireland (Eire) (+353)', flag: '🇮🇪' },
  { value: '972', label: 'Israel (+972)', flag: '🇮🇱' },
  { value: '39', label: 'Italy (+39)', flag: '🇮🇹' },
  { value: '1-658', label: 'Jamaica (+1-658)', flag: '🇯🇲' },
  { value: '1-876', label: 'Jamaica (+1-876)', flag: '🇯🇲' },
  { value: '81', label: 'Japan (+81)', flag: '🇯🇵' },
  { value: '962', label: 'Jordan (+962)', flag: '🇯🇴' },
  { value: '7', label: 'Kazakhstan (+7)', flag: '🇰🇿' },
  { value: '254', label: 'Kenya (+254)', flag: '🇰🇪' },
  { value: '686', label: 'Kiribati (+686)', flag: '🇰🇮' },
  { value: '383', label: 'Kosovo (+383)', flag: '🇽🇰' },
  { value: '965', label: 'Kuwait (+965)', flag: '🇰🇼' },
  { value: '996', label: 'Kyrgyzstan (+996)', flag: '🇰🇬' },
  { value: '856', label: 'Laos (+856)', flag: '🇱🇦' },
  { value: '371', label: 'Latvia (+371)', flag: '🇱🇻' },
  { value: '961', label: 'Lebanon (+961)', flag: '🇱🇧' },
  { value: '266', label: 'Lesotho (+266)', flag: '🇱🇸' },
  { value: '231', label: 'Liberia (+231)', flag: '🇱🇷' },
  { value: '218', label: 'Libya (+218)', flag: '🇱🇾' },
  { value: '423', label: 'Liechtenstein (+423)', flag: '🇱🇮' },
  { value: '370', label: 'Lithuania (+370)', flag: '🇱🇹' },
  { value: '352', label: 'Luxembourg (+352)', flag: '🇱🇺' },
  { value: '853', label: 'Macau (+853)', flag: '🇲🇴' },
  { value: '261', label: 'Madagascar (+261)', flag: '🇲🇬' },
  { value: '265', label: 'Malawi (+265)', flag: '🇲🇼' },
  { value: '60', label: 'Malaysia (+60)', flag: '🇲🇾' },
  { value: '960', label: 'Maldives (+960)', flag: '🇲🇻' },
  { value: '223', label: 'Mali (+223)', flag: '🇲🇱' },
  { value: '356', label: 'Malta (+356)', flag: '🇲🇹' },
  { value: '692', label: 'Marshall Islands (+692)', flag: '🇲🇭' },
  { value: '596', label: 'Martinique (+596)', flag: '🇲🇶' },
  { value: '222', label: 'Mauritania (+222)', flag: '🇲🇷' },
  { value: '230', label: 'Mauritius (+230)', flag: '🇲🇺' },
  { value: '262', label: 'Mayotte (+262)', flag: '🇾🇹' },
  { value: '52', label: 'Mexico (+52)', flag: '🇲🇽' },
  { value: '691', label: 'Micronesia, Federated States of (+691)', flag: '🇫🇲' },
  { value: '373', label: 'Moldova (+373)', flag: '🇲🇩' },
  { value: '377', label: 'Monaco (+377)', flag: '🇲🇨' },
  { value: '976', label: 'Mongolia (+976)', flag: '🇲🇳' },
  { value: '382', label: 'Montenegro (+382)', flag: '🇲🇪' },
  { value: '1-664', label: 'Montserrat (+1-664)', flag: '🇲🇸' },
  { value: '212', label: 'Morocco (+212)', flag: '🇲🇦' },
  { value: '258', label: 'Mozambique (+258)', flag: '🇲🇿' },
  { value: '95', label: 'Myanmar (+95)', flag: '🇲🇲' },
  { value: '264', label: 'Namibia (+264)', flag: '🇳🇦' },
  { value: '674', label: 'Nauru (+674)', flag: '🇳🇷' },
  { value: '977', label: 'Nepal (+977)', flag: '🇳🇵' },
  { value: '31', label: 'Netherlands (+31)', flag: '🇳🇱' },
  { value: '687', label: 'New Caledonia (+687)', flag: '🇳🇨' },
  { value: '64', label: 'New Zealand (+64)', flag: '🇳🇿' },
  { value: '505', label: 'Nicaragua (+505)', flag: '🇳🇮' },
  { value: '227', label: 'Niger (+227)', flag: '🇳🇪' },
  { value: '234', label: 'Nigeria (+234)', flag: '🇳🇬' },
  { value: '683', label: 'Niue (+683)', flag: '🇳🇺' },
  { value: '672-3', label: 'Norfolk Island (+672-3)', flag: '🇳🇫' },
  { value: '850', label: 'North Korea (+850)', flag: '🇰🇵' },
  { value: '389', label: 'North Macedonia (+389)', flag: '🇲🇰' },
  { value: '1-670', label: 'Northern Mariana Islands (+1-670)', flag: '🇲🇵' },
  { value: '47', label: 'Norway (+47)', flag: '🇳🇴' },
  { value: '968', label: 'Oman (+968)', flag: '🇴🇲' },
  { value: '92', label: 'Pakistan (+92)', flag: '🇵🇰' },
  { value: '680', label: 'Palau (+680)', flag: '🇵🇼' },
  { value: '970', label: 'Palestine (+970)', flag: '🇵🇸' },
  { value: '507', label: 'Panama (+507)', flag: '🇵🇦' },
  { value: '675', label: 'Papua New Guinea (+675)', flag: '🇵🇬' },
  { value: '595', label: 'Paraguay (+595)', flag: '🇵🇾' },
  { value: '51', label: 'Peru (+51)', flag: '🇵🇪' },
  { value: '63', label: 'Philippines (+63)', flag: '🇵🇭' },
  { value: '48', label: 'Poland (+48)', flag: '🇵🇱' },
  { value: '351', label: 'Portugal (+351)', flag: '🇵🇹' },
  { value: '1-787', label: 'Puerto Rico (+1-787)', flag: '🇵🇷' },
  { value: '1-939', label: 'Puerto Rico (+1-939)', flag: '🇵🇷' },
  { value: '974', label: 'Qatar (+974)', flag: '🇶🇦' },
  { value: '262', label: 'Reunion (+262)', flag: '🇷🇪' },
  { value: '40', label: 'Romania (+40)', flag: '🇷🇴' },
  { value: '7', label: 'Russia (+7)', flag: '🇷🇺' },
  { value: '250', label: 'Rwanda (+250)', flag: '🇷🇼' },
  { value: '599', label: 'Saba (+599)', flag: '🇧🇶' },
  { value: '590', label: 'Saint-Barthelemy (+590)', flag: '🇧🇱' },
  { value: '290', label: 'Saint Helena (+290)', flag: '🇸🇭' },
  { value: '1-869', label: 'Saint Kitts and Nevis (+1-869)', flag: '🇰🇳' },
  { value: '1-758', label: 'Saint Lucia (+1-758)', flag: '🇱🇨' },
  { value: '590', label: 'Saint Martin (French side) (+590)', flag: '🇲🇫' },
  { value: '508', label: 'Saint Pierre and Miquelon (+508)', flag: '🇵🇲' },
  { value: '1-784', label: 'Saint Vincent and the Grenadines (+1-784)', flag: '🇻🇨' },
  { value: '685', label: 'Samoa (+685)', flag: '🇼🇸' },
  { value: '378', label: 'San Marino (+378)', flag: '🇸🇲' },
  { value: '239', label: 'Sao Tome and Principe (+239)', flag: '🇸🇹' },
  { value: '966', label: 'Saudi Arabia (+966)', flag: '🇸🇦' },
  { value: '221', label: 'Senegal (+221)', flag: '🇸🇳' },
  { value: '381', label: 'Serbia (+381)', flag: '🇷🇸' },
  { value: '248', label: 'Seychelles (+248)', flag: '🇸🇨' },
  { value: '232', label: 'Sierra Leone (+232)', flag: '🇸🇱' },
  { value: '65', label: 'Singapore (+65)', flag: '🇸🇬' },
  { value: '599', label: 'Sint Eustatius (+599)', flag: '🇧🇶' },
  { value: '1-721', label: 'Sint Maarten (Dutch side) (+1-721)', flag: '🇸🇽' },
  { value: '421', label: 'Slovakia (+421)', flag: '🇸🇰' },
  { value: '386', label: 'Slovenia (+386)', flag: '🇸🇮' },
  { value: '677', label: 'Solomon Islands (+677)', flag: '🇸🇧' },
  { value: '252', label: 'Somalia (+252)', flag: '🇸🇴' },
  { value: '27', label: 'South Africa (+27)', flag: '🇿🇦' },
  { value: '82', label: 'South Korea (+82)', flag: '🇰🇷' },
  { value: '211', label: 'South Sudan (+211)', flag: '🇸🇸' },
  { value: '34', label: 'Spain (+34)', flag: '🇪🇸' },
  { value: '94', label: 'Sri Lanka (+94)', flag: '🇱🇰' },
  { value: '249', label: 'Sudan (+249)', flag: '🇸🇩' },
  { value: '597', label: 'Suriname (+597)', flag: '🇸🇷' },
  { value: '46', label: 'Sweden (+46)', flag: '🇸🇪' },
  { value: '41', label: 'Switzerland (+41)', flag: '🇨🇭' },
  { value: '963', label: 'Syria (+963)', flag: '🇸🇾' },
  { value: '886', label: 'Taiwan (+886)', flag: '🇹🇼' },
  { value: '992', label: 'Tajikistan (+992)', flag: '🇹🇯' },
  { value: '255', label: 'Tanzania (+255)', flag: '🇹🇿' },
  { value: '66', label: 'Thailand (+66)', flag: '🇹🇭' },
  { value: '670', label: 'Timor-Leste (+670)', flag: '🇹🇱' },
  { value: '228', label: 'Togo (+228)', flag: '🇹🇬' },
  { value: '690', label: 'Tokelau (+690)', flag: '🇹🇰' },
  { value: '676', label: 'Tonga (+676)', flag: '🇹🇴' },
  { value: '1-868', label: 'Trinidad and Tobago (+1-868)', flag: '🇹🇹' },
  { value: '290', label: 'Tristan da Cunha (+290)', flag: '🇸🇭' },
  { value: '216', label: 'Tunisia (+216)', flag: '🇹🇳' },
  { value: '90', label: 'Turkey (+90)', flag: '🇹🇷' },
  { value: '993', label: 'Turkmenistan (+993)', flag: '🇹🇲' },
  { value: '1-649', label: 'Turks and Caicos Islands (+1-649)', flag: '🇹🇨' },
  { value: '688', label: 'Tuvalu (+688)', flag: '🇹🇻' },
  { value: '256', label: 'Uganda (+256)', flag: '🇺🇬' },
  { value: '380', label: 'Ukraine (+380)', flag: '🇺🇦' },
  { value: '971', label: 'United Arab Emirates (+971)', flag: '🇦🇪' },
  { value: '44', label: 'United Kingdom (+44)', flag: '🇬🇧' },
  { value: '1', label: 'United States of America (+1)', flag: '🇺🇸' },
  { value: '598', label: 'Uruguay (+598)', flag: '🇺🇾' },
  { value: '998', label: 'Uzbekistan (+998)', flag: '🇺🇿' },
  { value: '678', label: 'Vanuatu (+678)', flag: '🇻🇺' },
  { value: '39', label: 'Vatican City State (+39)', flag: '🇻🇦' },
  { value: '58', label: 'Venezuela (+58)', flag: '🇻🇪' },
  { value: '84', label: 'Vietnam (+84)', flag: '🇻🇳' },
  { value: '1-340', label: 'U.S. Virgin Islands (+1-340)', flag: '🇻🇮' },
  { value: '681', label: 'Wallis and Futuna (+681)', flag: '🇼🇫' },
  { value: '967', label: 'Yemen (+967)', flag: '🇾🇪' },
  { value: '260', label: 'Zambia (+260)', flag: '🇿🇲' },
  { value: '263', label: 'Zimbabwe (+263)', flag: '🇿🇼' },
];

export const countries = countryCodes.map(({ value, label, flag }) => ({
  value,
  label: label.split(' (')[0], // Extract the country name
  flag,
}));