import React, { useState } from 'react';
import Select from 'react-select';
import { countryCodes, countries } from './data/countryCodes.ts';
import './QuoteForm.css';

interface QuoteFormProps {
  rv: string;
  onClose: () => void;
}

const QuoteForm: React.FC<QuoteFormProps> = ({ rv, onClose }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState<{ value: string; label: string; flag: string } | null>(null);
  const [city, setCity] = useState('');
  const [country, setCountry] = useState<{ value: string; label: string; flag: string } | null>(null);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone: string) => {
    const re = /^[0-9-]{1,20}$/;
    return re.test(phone);
  };

  const validateCity = (city: string) => {
    const re = /^[a-zA-Z0-9\s-]{1,50}$/;
    return re.test(city);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email || !validateEmail(email)) {
      alert('Please enter a valid email address');
      return;
    }
    if (!city || !validateCity(city)) {
      alert('Please enter a valid city name');
      return;
    }
    if (!country) {
      alert('Country is required');
      return;
    }
    if (phone && !validatePhone(phone)) {
      alert('Please enter a valid phone number');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/quotes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          rv,
          email,
          phone: phone ? `${countryCode?.value}_${phone}` : null,
          city,
          country: country?.label,
        }),
      });
      if (response.ok) {
        alert('Quote request submitted successfully');
      } else {
        alert('Failed to submit quote request');
      }
    } catch (error) {
      console.error('Error submitting quote request:', error);
      alert('An error occurred while submitting your request');
    }
    onClose();
  };

  return (
    <form className="quote-form" onSubmit={handleSubmit}>
      <h2>Compare offers from dealers for the <u>{rv}</u>:</h2>
      <div className='quote-form-levels'>
        <label>Email:</label>
        <input type="email" placeholder="example@mail.com" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </div>
      <div className="react-select-wrapper quote-form-levels">
      <label>Phone Country:</label>
      <Select
          className="react-select-container"
          classNamePrefix="react-select"
          options={countryCodes}
          value={countryCode}
          onChange={(selectedOption) => setCountryCode(selectedOption)}
          placeholder="Country Code"
          getOptionLabel={(option) => `${option.flag} ${option.label}`}
          getOptionValue={(option) => option.value}
        />
      </div>
      <div className="react-select-wrapper quote-form-levels">
        <label>Phone Number:</label>
        <input
          type="text"
          placeholder="012 3456 789"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          maxLength={15} /* Limit the input to 15 characters */
        />
      </div>
      <div className='quote-form-levels'>
        <label>City/Town:</label>
        <input
          type="text"
          placeholder="City Name"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          maxLength={50} /* Limit the input to 50 characters */
          required
        />
      </div>
      <div className="react-select-wrapper quote-form-levels">
        <label>Country:</label>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          options={countries}
          value={country}
          onChange={(selectedOption) => setCountry(selectedOption)}
          placeholder="Select Country"
          getOptionLabel={(option) => `${option.flag} ${option.label}`}
          getOptionValue={(option) => option.value}
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
}

export default QuoteForm;