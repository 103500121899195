import React, { useState, useEffect } from 'react';
import RVCard from './RVCard.tsx';
import RVFilter from './RVFilter.tsx';
import RVSort from './RVSort.tsx';
import { RV } from './types.ts';
import './RVGrid.css';

const RVGrid: React.FC = () => {
  const [rvs, setRVs] = useState<RV[]>([]);
  const [minPrice, setMinPrice] = useState<number | null>(null);
  const [maxPrice, setMaxPrice] = useState<number | null>(null);
  const [minLength, setMinLength] = useState<number | null>(null);
  const [maxLength, setMaxLength] = useState<number | null>(null);
  const [minHeight, setMinHeight] = useState<number | null>(null);
  const [maxHeight, setMaxHeight] = useState<number | null>(null);
  const [minWidth, setMinWidth] = useState<number | null>(null);
  const [maxWidth, setMaxWidth] = useState<number | null>(null);
  const [minGVW, setMinGVW] = useState<number | null>(null);
  const [maxGVW, setMaxGVW] = useState<number | null>(null);
  const [minPayload, setMinPayload] = useState<number | null>(null);
  const [maxPayload, setMaxPayload] = useState<number | null>(null);
  const [selectedParents, setSelectedParents] = useState<string[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [selectedSeries, setSelectedSeries] = useState<string[]>([]);
  const [availableParents, setAvailableParents] = useState<string[]>([]);
  const [availableBrands, setAvailableBrands] = useState<string[]>([]);
  const [availableSeries, setAvailableSeries] = useState<string[]>([]);
  const [sortCriteria, setSortCriteria] = useState<'price-asc' | 'price-desc' | 'name-asc' | 'name-desc' | 'length-asc' | 'length-desc' | 'width-asc' | 'width-desc' | 'height-asc' | 'height-desc' | 'payload-asc' | 'payload-desc' | 'gvw-asc' | 'gvw-desc'>('price-asc');

  useEffect(() => {
    const fetchRVs = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
	const response = await fetch(`${apiUrl}/api/rvs`);
        const data = await response.json();
        
        // Extract unique values for filters
        const parents = Array.from(new Set(data.map((rv: RV) => rv.parent)));
        const brands = Array.from(new Set(data.map((rv: RV) => rv.brand)));
        const series = Array.from(new Set(data.map((rv: RV) => rv.series)));

        setAvailableParents(parents);
        setAvailableBrands(brands);
        setAvailableSeries(series);

        // Apply filters
        const filteredData = data.filter((rv: RV) => {
          return (
            (minPrice === null || rv.price >= minPrice) &&
            (maxPrice === null || rv.price <= maxPrice) &&
            (minLength === null || rv.dimensions[0].length_m >= minLength) &&
            (maxLength === null || rv.dimensions[0].length_m <= maxLength) &&
            (minHeight === null || rv.dimensions[0].height_m >= minHeight) &&
            (maxHeight === null || rv.dimensions[0].height_m <= maxHeight) &&
            (minWidth === null || rv.dimensions[0].width_m >= minWidth) &&
            (maxWidth === null || rv.dimensions[0].width_m <= maxWidth) &&
            (minGVW === null || rv.weights[0].gvw_kg >= minGVW) &&
            (maxGVW === null || rv.weights[0].gvw_kg <= maxGVW) &&
            (minPayload === null || rv.weights[0].payload_kg >= minPayload) &&
            (maxPayload === null || rv.weights[0].payload_kg <= maxPayload) &&
            (selectedParents.length === 0 || selectedParents.includes(rv.parent)) &&
            (selectedBrands.length === 0 || selectedBrands.includes(rv.brand)) &&
            (selectedSeries.length === 0 || selectedSeries.includes(rv.series))
          );
        });

        // Apply sorting
        const sortedData = filteredData.sort((a, b) => {
          switch (sortCriteria) {
            case 'price-asc':
              return a.price - b.price;
            case 'price-desc':
              return b.price - a.price;
            case 'name-asc':
              return a.name.localeCompare(b.name);
            case 'name-desc':
              return b.name.localeCompare(a.name);
            case 'length-asc':
              return a.dimensions[0].length_m - b.dimensions[0].length_m;
            case 'length-desc':
              return b.dimensions[0].length_m - a.dimensions[0].length_m;
            case 'width-asc':
              return a.dimensions[0].width_m - b.dimensions[0].width_m;
            case 'width-desc':
              return b.dimensions[0].width_m - a.dimensions[0].width_m;
            case 'height-asc':
              return a.dimensions[0].height_m - b.dimensions[0].height_m;
            case 'height-desc':
              return b.dimensions[0].height_m - a.dimensions[0].height_m;
            case 'payload-asc':
              return a.weights[0].payload_kg - b.weights[0].payload_kg;
            case 'payload-desc':
              return b.weights[0].payload_kg - a.weights[0].payload_kg;
            case 'gvw-asc':
              return a.weights[0].gvw_kg - b.weights[0].gvw_kg;
            case 'gvw-desc':
              return b.weights[0].gvw_kg - a.weights[0].gvw_kg;
            default:
              return 0;
          }
        });

        setRVs(sortedData);
      } catch (error) {
        console.error('Error fetching RV data:', error);
      }
    };
    fetchRVs();
  }, [minPrice, maxPrice, minLength, maxLength, minHeight, maxHeight, minWidth, maxWidth, minGVW, maxGVW, minPayload, maxPayload, selectedParents, selectedBrands, selectedSeries, sortCriteria]);

  return (
    <div>
      <RVFilter
        minPrice={minPrice} maxPrice={maxPrice} minLength={minLength} maxLength={maxLength} minHeight={minHeight} maxHeight={maxHeight} minWidth={minWidth} maxWidth={maxWidth}
        minGVW={minGVW} maxGVW={maxGVW} minPayload={minPayload} maxPayload={maxPayload}
        selectedParents={selectedParents} selectedBrands={selectedBrands} selectedSeries={selectedSeries}
        setMinPrice={setMinPrice} setMaxPrice={setMaxPrice} setMinLength={setMinLength} setMaxLength={setMaxLength} setMinHeight={setMinHeight} setMaxHeight={setMaxHeight}
        setMinWidth={setMinWidth} setMaxWidth={setMaxWidth} setMinGVW={setMinGVW} setMaxGVW={setMaxGVW} setMinPayload={setMinPayload} setMaxPayload={setMaxPayload}
        setSelectedParents={setSelectedParents} setSelectedBrands={setSelectedBrands} setSelectedSeries={setSelectedSeries}
        availableParents={availableParents} availableBrands={availableBrands} availableSeries={availableSeries}
      />
      <RVSort sortCriteria={sortCriteria} setSortCriteria={setSortCriteria} />
      <div className="rv-grid">
        {rvs.map((rv, index) => (
          <RVCard key={`${rv.id}-${index}`} rv={rv} />
        ))}
      </div>
    </div>
  );
};

export default RVGrid;
