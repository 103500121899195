import React from 'react';
import './Header.css';

const Header: React.FC = () => {
  return (
    <header className="App-header">
      <img src="/logo72x64colour.png" alt="Logo" className="App-logo" />
      <h1>rvbig</h1>
    </header>
  );
}

export default Header;