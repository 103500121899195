import React from 'react';
import './RVSort.css';

interface RVSortProps {
  sortCriteria: 'price-asc' | 'price-desc' | 'name-asc' | 'name-desc' | 'length-asc' | 'length-desc' | 'width-asc' | 'width-desc' | 'height-asc' | 'height-desc' | 'payload-asc' | 'payload-desc' | 'gvw-asc' | 'gvw-desc';
  setSortCriteria: (criteria: 'price-asc' | 'price-desc' | 'name-asc' | 'name-desc' | 'length-asc' | 'length-desc' | 'width-asc' | 'width-desc' | 'height-asc' | 'height-desc' | 'payload-asc' | 'payload-desc' | 'gvw-asc' | 'gvw-desc') => void;
}

const RVSort: React.FC<RVSortProps> = ({ sortCriteria, setSortCriteria }) => {
  return (
    <div className="sort-options">
      <label>
        Sort by:
        <select value={sortCriteria} onChange={(e) => setSortCriteria(e.target.value as RVSortProps['sortCriteria'])}>
          <option value="price-asc">Price (Low to High)</option>
          <option value="price-desc">Price (High to Low)</option>
          <option value="name-asc">Name (A to Z)</option>
          <option value="name-desc">Name (Z to A)</option>
          <option value="length-asc">Length (Low to High)</option>
          <option value="length-desc">Length (High to Low)</option>
          <option value="width-asc">Width (Low to High)</option>
          <option value="width-desc">Width (High to Low)</option>
          <option value="height-asc">Height (Low to High)</option>
          <option value="height-desc">Height (High to Low)</option>
          <option value="payload-asc">Payload (Low to High)</option>
          <option value="payload-desc">Payload (High to Low)</option>
          <option value="gvw-asc">GVW (Low to High)</option>
          <option value="gvw-desc">GVW (High to Low)</option>
        </select>
      </label>
    </div>
  );
};

export default RVSort;